import React from "react";
import { graphql } from "gatsby";
import TableOfContents from "../components/TableOfContents";
import { get } from "lodash";

const InformChangeLog = (props) => (
  <TableOfContents
    name="InFORM™ Changelog"
    entries={get(props, "data.allContentfulChangelog.nodes")}
    target="date"
  />
);

export const query = graphql`
  {
    allContentfulChangelog(
      filter: { product: { eq: "InFORM" } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        date
        version
        notes {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`;

export default InformChangeLog;
